import { combineReducers } from 'redux';
import currentTab from './currentTab';
import finishedTabs from './finishedTabs';
import evaluation from './evaluation';
import data from './data';

export default combineReducers({
  currentTab,
  finishedTabs,
  evaluation,
  data,
});
