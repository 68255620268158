import React from 'react';
import { connect } from 'react-redux';
import Progress from '../Progress/Progress';
import configuration from '../../configuration.json';
import {
  getCurrentTabData,
  calculateRatings,
  calculateTotalRating,
} from '../../utils/DataHelper';
import './Evaluation.css';

class Evaluation extends React.Component {
  render() {
    const { isOpen, data } = this.props;
    if (!isOpen) return <React.Fragment></React.Fragment>;

    return (
      <div className='evaluation'>
        <h2>Auswertung</h2>
        {Object.keys(configuration.products).map((product) => (
          <div key={product}>
            <h3>{product}</h3>
            <Progress
              progress={calculateRatings(data[product], product)}
              products={configuration.products[product]}
              total={calculateTotalRating(data[product])}
            />
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  data: state.data,
  evaluation: state.evaluation,
  isOpen: state.evaluation.isOpen,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Evaluation);
