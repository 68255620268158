import React from 'react';
import { connect } from 'react-redux';
import './Evaluation.css';
import { toggleEvaluation } from '../../actions';

class EvaluationButton extends React.Component {
  render() {
    const { isOpen, toggleEvaluation } = this.props;

    return (
      <div className='evaluationButton' onClick={() => toggleEvaluation()}>
        {isOpen ? <i className='fa fa-times'></i> : 'Auswertung'}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  isOpen: state.evaluation.isOpen,
});

const mapDispatchToProps = (dispatch) => ({
  toggleEvaluation: () => dispatch(toggleEvaluation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EvaluationButton);
