export const setTab = (tab) => ({
  type: 'SET_TAB',
  tab,
});

export const finishTab = (name) => ({
  type: 'FINISH_TAB',
  name,
});

export const setPoint = (data) => ({
  type: 'SET_POINT',
  data,
});

export const resetData = () => ({
  type: 'RESET_DATA',
});

export const toggleEvaluation = () => ({
  type: 'TOGGLE_EVALUATION',
});
