import React from 'react';
import './PointLegend.css';
import PointLegendItem from './PointLegendItem';

export default class PointLegend extends React.Component {
  render() {
    return (
      <div className='pointLegend'>
        <h3>Wie wichtig ist es für Sie?</h3>
        <div className='pointLegend-inner'>
          <PointLegendItem circleLabel='1' label='gar nicht' color='#e67a79' />
          <PointLegendItem
            circleLabel='2'
            label='nicht wichtig'
            color='#fba44d'
          />
          <PointLegendItem
            circleLabel='3'
            label='weniger wichtig'
            color='#ffd770'
          />
          <PointLegendItem circleLabel='4' label='wichtig' color='#8accca' />
          <PointLegendItem
            circleLabel='5'
            label='sehr wichtig'
            color='#74ab33'
          />
        </div>
      </div>
    );
  }
}
