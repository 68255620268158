const currentTab = (
  state = { product: 'Software', tab: 'Point of Sale' },
  action
) => {
  switch (action.type) {
    case 'SET_TAB':
      return { tab: action.tab.label, product: action.tab.product };
    default:
      return state;
  }
};

export default currentTab;
