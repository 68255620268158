import React from 'react';
import DataRatingRow from './DataRatingRow';

export default function SubHeadline({
  data,
  currentTab,
  onChange,
  subHeadline,
}) {
  return data.map((row, index) => (
    <DataRatingRow
      {...row}
      {...currentTab}
      subHeadline={subHeadline}
      key={index}
      index={index}
      onChange={() => onChange()}
    />
  ));
}
