import configuration from '../configuration.json';

export const getCurrentTabData = (data, currentTab) =>
  data[currentTab.product][currentTab.tab];

export function getTabs(data) {
  var tabs = [];

  Object.keys(data).map((product, index) => {
    Object.keys(data[product]).forEach((label) => {
      tabs = [...tabs, { label, product }];
    });
  });

  return tabs;
}

export function calculateTabRatings(data, productKey) {
  let progress = configuration.products[productKey].reduce((obj, product) => {
    obj[product.name] = 0;
    return obj;
  }, {});
  let productKeyArray = configuration.products[productKey].map(
    (product) => product.name
  );

  Object.keys(data).forEach(
    // Loop Through Subheadlines
    (subHeadlines, index) => {
      Object.entries(data[subHeadlines]).forEach(([index, row]) => {
        // Loop Through rows of Subheadlines
        if (row.rating) {
          productKeyArray.forEach((product) => {
            progress[product] += row.rating * row.weighting * row[product];
          });
        }
      });
    }
  );

  return progress;
}

export function calculateRatings(data, productKey) {
  let progress = configuration.products[productKey].reduce((obj, product) => {
    obj[product.name] = 0;
    return obj;
  }, {});
  let productKeyArray = configuration.products[productKey].map(
    (product) => product.name
  );

  Object.keys(data).forEach((tab, index) => {
    Object.keys(data[tab]).forEach((subHeadlines, index) => {
      // Loop Through Subheadlines
      Object.entries(data[tab][subHeadlines]).forEach(([index, row]) => {
        // Loop Through rows of Subheadlines
        if (row.rating) {
          productKeyArray.forEach((product) => {
            progress[product] += row.rating * row.weighting * row[product];
          });
        }
      });
    });
  });

  return progress;
}

export function calculateTotalTabRating(data) {
  let total = 0;
  let rows = [];

  Object.keys(data).forEach((key) => {
    rows = [...rows, ...data[key]];
  });

  rows.forEach((row) => {
    total += row.weighting * 4;
  });

  return total;
}

export function calculateTotalRating(data) {
  let total = 0;
  let rows = [];

  Object.keys(data).forEach((key) => {
    Object.keys(data[key]).forEach((subKey) => {
      rows = [...rows, ...data[key][subKey]];
    });
  });

  rows.forEach((row) => {
    total += row.weighting * 4;
  });

  return total;
}
