import React from 'react';
import { connect } from 'react-redux';
import './Tabs.css';
import Tab from './Tab';
import $ from 'jquery';
import { getTabs } from '../../utils/DataHelper';

class Tabs extends React.Component {
  componentDidMount() {
    $('.tabs').scroll(() => {
      if ($('.tabs').scrollLeft() === 0) {
        $('.scroll.left').hide();
      } else {
        $('.scroll.left').css('display', 'flex');
      }

      if ($('.tabs').outerWidth() < $('.tabs').scrollLeft() - 250) {
        $('.scroll.right').hide();
      } else {
        $('.scroll.right').show();
      }
    });
  }
  scrollLeft() {
    const currentScrollPosition = $('.tabs').scrollLeft();
    $('.tabs').scrollLeft(currentScrollPosition - 250);
  }

  scrollRight() {
    const currentScrollPosition = $('.tabs').scrollLeft();
    $('.tabs').scrollLeft(currentScrollPosition + 250);
  }

  render() {
    const { tabs, currentTab } = this.props;

    return (
      <div className='tabs-wrapper'>
        <div className='scroll left' onClick={() => this.scrollLeft()}>
          <i className='fa fa-chevron-left'></i>
        </div>
        <div className='tabs'>
          {tabs.map((tab, index) => (
            <Tab {...tab} key={index} isActive={tab.label === currentTab.tab} />
          ))}
        </div>
        <div className='scroll right' onClick={() => this.scrollRight()}>
          <i className='fa fa-chevron-right'></i>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentTab: state.currentTab,
  tabs: getTabs(state.data),
});

export default connect(mapStateToProps)(Tabs);
