import React from 'react';
import './Progress.css';

export default class Progress extends React.Component {
  calculatePercentage(total, value) {
    if (value === 0) {
      return 0;
    }

    return (100 / total) * value;
  }

  render() {
    const { progress, products, total } = this.props;
    console.log(progress, total);
    return (
      <div className='progress'>
        {products.map((product, index) => (
          <div className='progress-item' key={index}>
            <label>{product.name}</label>
            <div className='progress-bar'>
              <div
                className='progress-bar-inner'
                style={{
                  width: `${this.calculatePercentage(
                    total,
                    progress[product.name]
                  )}%`,
                  backgroundColor: product.color,
                }}
              ></div>
              <span className='progress-bar-label'>
                {Math.round(
                  this.calculatePercentage(total, progress[product.name])
                )}
                %
              </span>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
