import React from 'react';
import { connect } from 'react-redux';
import { setPoint } from '../../actions';

class DataRatingRow extends React.Component {
  setPoint(obj) {
    this.props.setPoint(obj);
    this.props.onChange();
  }

  render() {
    const { label, index, tab, product, rating, subHeadline } = this.props;

    return (
      <div className='dataRatingRow'>
        <div className='dataRatingRow-label'>{label}</div>
        <div className='dataRatingRow-points'>
          <div
            className={`dataRatingRow-point ${rating === 0 ? 'active' : ''}`}
            data-value='0'
            onClick={() =>
              this.setPoint({
                subHeadline,
                product,
                tab,
                row: index,
                point: 0,
              })
            }
          ></div>
          <div
            className={`dataRatingRow-point ${rating === 1 ? 'active' : ''}`}
            data-value='1'
            onClick={() =>
              this.setPoint({
                subHeadline,
                product,
                tab,
                row: index,
                point: 1,
              })
            }
          ></div>
          <div
            className={`dataRatingRow-point ${rating === 2 ? 'active' : ''}`}
            data-value='2'
            onClick={() =>
              this.setPoint({
                subHeadline,
                product,
                tab,
                row: index,
                point: 2,
              })
            }
          ></div>
          <div
            className={`dataRatingRow-point ${rating === 3 ? 'active' : ''}`}
            data-value='3'
            onClick={() =>
              this.setPoint({
                subHeadline,
                product,
                tab,
                row: index,
                point: 3,
              })
            }
          ></div>
          <div
            className={`dataRatingRow-point ${rating === 4 ? 'active' : ''}`}
            data-value='4'
            onClick={() =>
              this.setPoint({
                subHeadline,
                product,
                tab,
                row: index,
                point: 4,
              })
            }
          ></div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setPoint: (id) => dispatch(setPoint(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DataRatingRow);
