import jsonData from '../data.json';

const data = (state = jsonData, action) => {
  switch (action.type) {
    case 'RESET_DATA':
      return jsonData;
    case 'SET_POINT':
      state[action.data.product][action.data.tab][action.data.subHeadline][
        action.data.row
      ].rating = action.data.point;
      return state;
    default:
      return state;
  }
};

export default data;
