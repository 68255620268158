import React from 'react';
import { Provider } from 'react-redux';
import { createStore } from 'redux';

import { saveState } from './localStorage';
import Tabs from './Components/Tabs/Tabs';
import DataRating from './Components/DataRating/DataRating';
import Evaluation from './Components/Evaluation/Evaluation';
import EvaluationButton from './Components/Evaluation/EvaluationButton';

import './App.css';
import rootReducer from './reducers';

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

store.subscribe(() => {
  saveState({
    data: store.getState().data,
  });
});

function App() {
  return (
    <Provider store={store}>
      <div className='App'>
        <header>
          <h1>Berat-O-Mat</h1>
          <EvaluationButton />
        </header>
        <div className='content'>
          <Tabs />
          <DataRating />
          <Evaluation />
        </div>
      </div>
    </Provider>
  );
}

export default App;
