import React from 'react';
import { connect } from 'react-redux';
import './Tabs.css';
import { setTab } from '../../actions';

class Tab extends React.Component {
  render() {
    const { label, product, setTab, isActive, finished } = this.props;

    return (
      <div
        className={`tab ${isActive ? 'active' : ''}`}
        onClick={() => setTab({ label, product })}
      >
        {finished && <i className='fa fa-check-circle'></i>}
        {label}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return { finished: state.finishedTabs.includes(props.label) };
};

const mapDispatchToProps = (dispatch) => ({
  setTab: (id) => dispatch(setTab(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tab);
