import React from 'react';

export default function PointLegendItem({ circleLabel, label, color }) {
  return (
    <div className='pointLegend-item'>
      <div className='pointLegend-item-circle-wrapper'>
        <div
          className='pointLegend-item-circle'
          style={{ backgroundColor: color }}
        >
          <label>{circleLabel}</label>
        </div>
      </div>
      <div className='pointLegend-item-label'>{label}</div>
    </div>
  );
}
