const evaluation = (state = { isOpen: false }, action) => {
  switch (action.type) {
    case 'TOGGLE_EVALUATION':
      return { ...state, isOpen: !state.isOpen };
    default:
      return state;
  }
};

export default evaluation;
