import React from 'react';
import { connect } from 'react-redux';
import './DataRating.css';
import Progress from '../Progress/Progress';
import PointLegend from '../PointLegend/PointLegend';
import SubHeadline from './SubHeadline';
import configuration from '../../configuration.json';
import { finishTab } from '../../actions';
import {
  getCurrentTabData,
  calculateTabRatings,
  calculateTotalTabRating,
} from '../../utils/DataHelper';

class DataRating extends React.Component {
  isFinished() {
    const { data, currentTab } = this.props;
    const tabData = getCurrentTabData(data, currentTab);
    let isFinished = true;

    Object.keys(tabData).forEach(
      // Loop Through Subheadlines
      (subHeadlines, index) => {
        Object.entries(tabData[subHeadlines]).forEach(([index, row]) => {
          // Loop Through rows of Subheadlines
          if (!Number.isInteger(row.rating)) {
            isFinished = false;
          }
        });
      }
    );

    return isFinished;
  }

  onChange() {
    if (this.isFinished()) {
      this.props.finishTab(this.props.currentTab.tab);
    }

    this.forceUpdate();
  }

  render() {
    const { data, currentTab } = this.props;
    const tabData = getCurrentTabData(data, currentTab);
    const progress = calculateTabRatings(tabData, currentTab.product);
    const total = calculateTotalTabRating(tabData);

    return (
      <React.Fragment>
        <Progress
          total={total}
          progress={progress}
          products={configuration.products[currentTab.product]}
        />
        <PointLegend />
        <div className='dataRating'>
          {Object.keys(tabData).map((subHeadline, index) => (
            <div className='dataRating-sub' key={index}>
              <div className='dataRating-sub-label'>{subHeadline}</div>
              <SubHeadline
                onChange={() => this.onChange()}
                subHeadline={subHeadline}
                data={tabData[subHeadline]}
                currentTab={currentTab}
              />
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.data,
  currentTab: state.currentTab,
});

const mapDispatchToProps = (dispatch) => ({
  finishTab: (name) => dispatch(finishTab(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DataRating);
