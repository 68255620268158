const finishedTabs = (state = [], action) => {
  switch (action.type) {
    case 'FINISH_TAB':
      if (state.includes(action.name)) {
        return state;
      }
      return [...state, action.name];
    default:
      return state;
  }
};

export default finishedTabs;
